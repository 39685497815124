// Color palette
$blue: #007bff; //Primary
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$gray: #6c757d;
$gray-dark: #343a40;
$success: #28a745;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;

// Screen width breakpoints for dynamic design
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Local fonts
@font-face {
  font-family: 'Galano Grotesque';
  src: url(assets/fonts/galanogrotesque-extralight_1_1-webfont.eot)
}
@font-face {
  font-family: 'Gilroy Bold';
  src: url(assets/fonts/Gilroy-Medium.ttf) format('truetype')
}

// General styling
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  vertical-align: top;
  max-width:100vw;
}
body {
  background-color:#f9fbf8;
}
body, #root, .App, .va {
  min-height: 100vh;
  overflow-x: hidden;
  min-width: 100%;
  max-width: 100%;
}
h1, h2, h3 {
  font-family: "Gilroy Bold", sans-serif;
  font-weight: 600;
  color:$gray-dark;
  margin-bottom: 12px;
  letter-spacing: 0.02em;
}
h1 {
  text-align:center;
}
p, li {
  font-family: "Galano Grotesque", sans-serif;
  font-weight: 400;
  color:$gray;
  line-height: 1.6em;
}
ul {
  padding-left:20px;
  list-style-type:none;
}
a {
  font-family: "Galano Grotesque", sans-serif;
  font-weight: 400;
  color:$blue;
  text-decoration: none;
}
img {
  width: 100%;
  user-select: none;
  -webkit-user-drag: none;
}
button {
  display: block;
  border:unset;
}
// Max width container
.mw {
  position: relative;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
}
// Auto padder
.ap {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
// Vertical aligner
.va {
  display: table;
  width: 100%;
  height: 100%;
}
.var {
  display: table-cell;
  vertical-align: middle;
}
div {
  position:relative;
}
.active div {
  opacity:1 !important;
}
form button {
  margin-bottom:0 !important;
}

textarea {
  width: 100%;
  min-height: 120px;
  margin-top:10px;
  padding:12px;
  border: 1px solid #eee;
  border-radius: 2px;
  background-color: #f1f1f1;
}

@media screen and (max-width: $breakpoint-sm) {
  .ap {
    padding-left: 12px;
    padding-right: 12px;
  }
}